.main_container_mobile,
.main_container_tv {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  min-height: 100%;
}

.main_container_mobile {
  margin: 5px;
  justify-content: center;
  zoom: 0.7;
}

.main_item_mobile {
  flex-basis: 100%;
  height: 500px;
}

.main_item_tv {
  flex-basis: 33%;
  height: 50vh;
}

.main_title_mobile {
  display: none;
}

.main_title_area {
  background-color: #afd4db;
  font-size: 60px;
  font-weight: bold;
  color: rgb(255, 255, 255);
  justify-content: center;
  line-height: 50vh;
  border-radius: 0 0 8px 0;
}

.title_text {
  height: 10%;
  padding-top: 15px;
  padding-bottom: 10px;
  padding-left: 20px;
  font-weight: bold;
  font-size: 1.7rem;
  color: rgba(51, 51, 51, 0.9);
}

.tour_main {
  height: 100%;
}

.tour_type,
.tour_radius {
  margin-left: 15px;
}

.tour_search_button {
  margin-left: 15px;
  width: 80px;
}

.tour_total_count {
  margin-left: 20px;
}

.tour_count {
  margin-left: 3px;
  color: blue;
}

.tour_image_list {
  display: inline-flex;
  align-items: stretch;
}

.tour_image_list_item {
  height: 310px;
  border: 0.4px solid darkgray;
  margin-right: 30px;
}

.apartment_main,
.subway_main,
.movie_main {
  height: 100%;
}

.apartment_main,
.subway_main,
.movie_main {
  background-color: #ffffff;
}

.apartment_list,
.subway_list,
.movie_list {
  height: 85%;
  padding-left: 20px;
  padding-right: 20px;
  color: #4e5b89;
  font-size: 20px;
  font-weight: 700;
  line-height: 37px;
}

.apartment_list_name,
.subway_list_item,
.movie_list_name {
  display: flex;
  justify-content: space-between;
}

.apartment_list_price {
  text-align: end;
  margin-top: -20px;
  /* line-height: 24px; */
  font-size: 24px;
  font-style: italic;
  background-color: rgba(207, 195, 195, 0.4);
}

.forecast_list,
.restaurant_list {
  padding-left: 20px;
  padding-right: 20px;
  color: #ffffff;
  font-size: 20px;
  font-weight: 700;
  line-height: 37px;
}

.exchange-box,
.forecast_main,
.restaurant_main {
  background-color: #7796ac;
  font-size: 2vh;
  height: 100%;
  overflow: hidden;
  border-radius: 8px 8px 0 0;
}

.restaurant_main {
  background-color: #6cb9d0;
}

.restaurant_name,
.pharm_name {
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
}

.restaurant_addr,
.pharm_addr {
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 7px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.exchange-rate-area {
  width: 92%;
  /* position: absolute;
  top: 50%;
  transform: translate(0, -50%) */
}

.exchange-rate-content {
  padding-left: 20px;
  padding-right: 20px;
}

.exchange-country-name {
  font-size: 1.2em;
  font-weight: 600;
  line-height: 1.5em;
  color: white;
}

.exchange-rate-info {
  font-size: 1em;
  color: #f5f5f5;
  margin-top: 0.25em;
  line-height: 1.2em;
}

.air-area {
  height: 100%;
  background-color: #dfe8e8;
  border-radius: 0 0 0 8px;
}

.air-area-container {
  padding-left: 20px;
  padding-right: 20px;
}

.air-title {
  font-size: 24px;
  line-height: 50px;
  font-weight: 700;
  color: rgba(51, 51, 51, 0.9);
  white-space: nowrap;
  margin-top: 15px;
}

.air-area-date,
.apartment_update_date {
  text-align: right;
  color: #4e5b89;
  font-weight: 400;
}

.apartment_update_date {
  margin-top: 20px;
  margin-right: 20px;
}

.gauge-wrap {
  position: relative;
  padding-bottom: 0px;
  margin-top: 0px;
}

.gauge-wrap .gauge-item:not(:first-of-type) {
  padding-top: 2px;
  margin-top: 0px;
}

.card-content {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.7);
  line-height: 20px;
  max-height: 56px;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 0;
  margin-bottom: 35px;
}

.name {
  padding-top: 8px;
  padding-bottom: 8px;
  color: #4e5b89;
  font-size: 20px;
  font-weight: 700;
  line-height: 37px;
}

.name.air {
  font-size: 23px;
  line-height: 4px;
}

.name.sub {
  font-size: 16px;
}

.box {
  width: 120px;
  height: 60px;
  line-height: 60px;
  font-size: 30px;
  text-align: center;
  color: white;
}

.box.air_mobile {
  width: 100px;
  height: 55px;
  line-height: 55px;
}

.covid19_main {
  height: 100%;
}

.covid19_content {
  margin: auto;
  width: 90%;
}

.covid19_loc_title {
  font-size: 25px;
  text-decoration: underline;
  margin-top: 18px;
  margin-bottom: 8px;
  text-align: center;
}

.covid19_loc_section {
  display: grid;
  color: #494949;
  border-radius: 10px;
  box-shadow: 0px 2px 1px 0px #132e46;
  grid-template-columns: 1fr 1fr;
  overflow: hidden;
}

.covid19_item {
  background-color: #6cb9d0;
  padding: 8px 0 8px 0;
  text-align: center;
}
.covid19_number {
  font-size: 30px;
  font-weight: bold;
}

.covid19_death {
  background-color: #157390;
  color: rgba(255, 255, 255, 0.9);
  padding: 8px 0 8px 0;
}

.date_area {
  color: black;
  font-weight: 400;
  text-align: end;
  font-size: 14px;
  margin-top: -4px;
}

.movie_main {
  background-color: #dfe8e8;
  border-radius: 8px 8px 0 0;
}
