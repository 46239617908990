.staggered-list-content {
  width: 400px;
  position: absolute;
  top: 2rem;
  right: -80rem;
	overflow: hidden;
  display: none;
}
.list {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 4rem);
  padding: 0;
}
.list li {
  width: 100%;
}
.list li + li {
  margin-top: 1rem;
}
.listItem {
  width: 100%;
  cursor: pointer;
  background-color: #d0d0d0;
  overflow: hidden;
}
.listItemContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
}
.avatar {
  width: 4rem;
  height: 4rem;
  border-radius: 100px;
  background-color: grey;
  margin-right: 2rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.avatarExpanded {
  width: 8rem;
  height: 8rem;
  margin-right: 0;
  margin-bottom: 1rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.description > div {
  /* background-color: grey; */
  /* width: 14rem; */
  border-radius: 6px;
  min-height: 0.5rem;
  color: dimgrey;
  display: inline-block;
}
.description > div:nth-of-type(1) {
  font-size: x-large;
  font-weight: bolder;
  color: black;
}
.description > div:nth-of-type(2) {
  /* width: 11rem; */
}
.description > div:nth-of-type(3) {
  /* width: 8rem; */
}
.description > div + div {
  margin-left: 0.8rem;
}
.expandedListItem .description > div + div {
  margin-top: 0.5rem;
  margin-left: 0;
}
.expandedListItem .description {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.expandedListItem {
  cursor: pointer;
  background-color: #d0d0d0;
}
.expandedListItemContent {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.additional-content {
  width: 100%;
  margin-top: 2rem;
}

.additional-content > div {
  opacity: 0;
  border-radius: 3px;
  background-color: gray;
  height: 5rem;
  color: #424242;
}
.additional-content > div:nth-of-type(1) {
  background-color: transparent;
  font-size: large;
}
.additional-content > .attachedImage {
  height: auto;
  background-color: transparent;
}
.additional-content img {
  width: 100%;
  height: auto;
  border-radius: 15px;
  object-fit: contain;
}

/* content fade in animations */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
  }
}

.animated-in .additional-content > div {
  animation: fadeIn 0.4s forwards;
}

.additional-content > div:nth-of-type(2) {
  animation-delay: 0.15s;
}
.additional-content > div:nth-of-type(3) {
  animation-delay: 0.3s;
}
.additional-content > div + div {
  margin-top: 1rem;
}

.staggered-list-content.show {
  display: block;
}

.staggered-list-content.type1{
	animation: type_ani1 1000ms 500ms ease forwards;
}
.staggered-list-content.type1_off{
  right: 2rem;
	animation: type_ani1_off 1000ms 500ms ease forwards;
}
@keyframes type_ani1{
  from {
      right: -80rem; /* 애니메이션 시작 */
  }
  to {
      right: 2rem; /* 애니메이션 종료 */
  }
}
@keyframes type_ani1_off{
  from {
      right: 2rem; /* 애니메이션 시작 */
  }
  to {
      right: -80rem; /* 애니메이션 종료 */
  }
}
